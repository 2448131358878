<template>
  <div class="slogan">
    <div
      class="slogan__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="slogan__content">
        <div class="slogan__content__span">
          <div></div>
          <p>slogan</p>
        </div>
        <div class="slogan__content__detail">
          <h2>smart & speed</h2>
          <h2>solutions</h2>
        </div>
      </div>
      <div class="slogan__block">
        <div class="slogan__block__item smart">
          <img v-lazy="sloganData[0].icon" alt="smart" />
          <h3>{{ sloganData[0].title }}</h3>
          <span>{{ sloganData[0].sub }}</span>
        </div>
        <div class="slogan__block__item--element">
          <div class="circle-left"></div>
          <div class="line"></div>
          <div class="circle-right"></div>
        </div>
        <div class="slogan__block__item speed">
          <img v-lazy="sloganData[1].icon" alt="speed" />
          <h3>{{ sloganData[1].title }}</h3>
          <span>{{ sloganData[1].sub }}</span>
        </div>
        <div class="slogan__block__item--element">
          <div class="circle-left"></div>
          <div class="line"></div>
          <div class="circle-right"></div>
        </div>
        <div class="slogan__block__item solutions">
          <img v-lazy="sloganData[2].icon" alt="solutions" />
          <h3>{{ sloganData[2].title }}</h3>
          <span>{{ sloganData[2].sub }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Slogan',
  data() {
    return {
      sub: this.$t('about_us').slogan,
      sloganData: [
        {
          icon: '/images/icons/aboutus/smart.svg',
          title: 'Smart'
        },
        {
          icon: '/images/icons/aboutus/speed.svg',
          title: 'Speed'
        },
        {
          icon: '/images/icons/aboutus/solutions.svg',
          title: 'Solutions'
        }
      ]
    };
  },
  mounted() {
    this.sloganData = this.sloganData.map((item, indx) => ({
      ...item,
      sub: this.sub[indx]
    }));
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.sub = this.$t('about_us').slogan;
      this.sloganData = this.sloganData.map((item, indx) => ({
        ...item,
        sub: this.sub[indx]
      }));
    }
  }
};
</script>

<style lang="scss" scroped>
.slogan {
  padding: 50px 0;
  width: 100%;
  background: url('/images/aboutus/bg-slogan.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .slogan__container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    margin: 0 auto;
  }
  .slogan__content {
    text-align: center;
    margin: 20px 0;
    .slogan__content__span {
      position: relative;
      z-index: 0;
      & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12%;
        height: 3px;
        background-color: #ffd942;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
    p {
      font-weight: 600;
      font-size: 18px;
      color: var(--grey-text);
      text-transform: uppercase;
      padding: 0 16px;
      width: fit-content;
      background-color: #fffbf9;
      margin: 0 auto;
    }

    .slogan__content__detail {
      h2 {
        color: var(--blue-light);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 0;
        line-height: 52px;
      }
    }
  }
  .slogan__block {
    display: flex;
    justify-content: center;
    align-items: center;
    .slogan__block--element {
      font-weight: 600;
      font-size: 30px;
    }
  }
  .slogan__block__item {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 280px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 40px;
    border: 5px solid var(--orange);
    text-align: center;
    border-radius: 30px;
    position: relative;
    img {
      width: 68px;
      height: 68px;
    }
    h3 {
      font-size: 22px;
      font-weight: 700;
      color: var(--orange);
    }
    span {
      font-weight: 400;
      font-size: 18px;
      color: var(--grey-text);
    }
  }
  .slogan__block__item--element {
    position: relative;
    height: 100px;
    width: 100px;
    background: #fffbf9;
    z-index: 9;
    margin: 0 -10px;
    .circle-left {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: var(--orange);
      top: 0px;
      left: -7.5px;
    }
    .circle-right {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: var(--orange);
      bottom: 0px;
      right: -7.5px;
    }
    .line {
      position: absolute;
      height: 5px;
      width: 137px;
      background: var(--orange);
      transform: rotate(130deg);
      top: 49%;
      border-radius: 20px;
      left: -19%;
      &::after {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: var(--orange);
      }
      &::before {
        content: '';
        position: absolute;
      }
    }
  }
}
@media (max-width: 1268px) {
  .slogan {
    padding: 50px 0;
    .slogan__block {
      flex-direction: column;
    }
    .slogan__block__item {
      width: 500px;
      height: 300px;
    }
    .slogan__block__item--element {
      position: relative;
      height: 100px;
      width: 100px;
      background: #fffbf9;
      z-index: 20;
      margin: -10px;
      .circle-left {
        top: -7.5px;
        left: 0px;
      }
      .circle-right {
        bottom: -7.5px;
        right: 0px;
      }
      .line {
        position: absolute;
        height: 5px;
        width: 135px;
        background: var(--orange);
        transform: rotate(140deg);
        top: 47%;
        border-radius: 20px;
        left: -17%;
        &::after {
          content: '';
          position: absolute;
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background: var(--orange);
        }
        &::before {
          content: '';
          position: absolute;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .slogan {
    padding: 50px 0;
  }
  .slogan .slogan__block__item {
    width: 100%;
  }
  .slogan__content__detail {
    h2 {
      font-size: 32px;
    }
  }
}
</style>
